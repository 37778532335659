import React, { useEffect, useLayoutEffect, useState } from 'react';
import { useHeroes } from '../../api/use-heroes';
import { Button, Card, Counter } from '../components';
import { GridLayout } from '../layout';
import { useUserBalancesContext } from '../../stores';
import { useTransaction } from '../../hooks';
import { TransactionFees } from '../features';
import { usdFormatter } from '../../utils/helpers';
import { IPFS_GATEWAY_URL, ZHERO_IMG_CID, ZHERO_MAX, ZHERO_PRICE } from '../../utils/constants';
import { CongratsModal } from '../modals';
import { HeroGallery } from '../features/hero-gallery';

function ZeroHero() {
    const { eth, heroes } = useUserBalancesContext();
    const { submitTx, isSuccess } = useTransaction();
    const { redeem, gasFees, getGasFees, isWhitelisted, isRedeeming } = useHeroes();
    const [amount, setAmount] = useState(1);

    const handleSubmit = async () => {
        await submitTx(async () => {
            const redeemRes = await redeem(amount);
            return redeemRes;
        }, true);
        setAmount(1);
    };

    // On amount change
    useEffect(() => {
        const getter = async () => {
            await getGasFees(amount, 'mint');
        };
        getter();
    }, [amount]);

    return (
        <>
            <div className="flex justify-center">
                <GridLayout centeredItems className="max-w-screen-xl">
                    <div className="flex flex-col text-lg">
                        <h2 className="text-xl font-bold">
                            ZERO Heroes are the foundation of the ZERO Network.
                        </h2>
                        <br />
                        <p>
                            By purchasing this NFT, you will be providing liquidity to the ZERO/ETH
                            pair on Uniswap. The ZERO Hero collection uses the{' '}
                            <a
                                className="text-brand-100 underline transition duration-150 hover:text-brand-900"
                                href=""
                            >
                                Diamond Hands NFT
                            </a>{' '}
                            smart contract to incentivize holding the NFT, in turn providing you
                            with yield in the form of ZERO tokens.
                        </p>
                        <br />
                        <p>
                            With the{' '}
                            <a
                                className="text-brand-100 underline transition duration-150 hover:text-brand-900"
                                href=""
                            >
                                Diamond Hands NFT
                            </a>{' '}
                            smart contract, the trick is that each subsequent redemption will
                            receive proportionally more than the previous one. This penalizes early
                            redemptions and ideally encourages NFT holders to exchange their NFTs on
                            the open market vs redeeming.
                        </p>
                        <br />
                        <p>
                            Be a ZERO Hero now to earn passive yields and provide stability to ZERO
                            Network.
                        </p>
                        <br />
                        <HeroGallery title="Your Heroes" />
                    </div>

                    <Card className="flex flex-col gap-4 md:mb-6 sm:max-w-xs h-fit">
                        <div className="flex flex-col gap-3">
                            <img
                                src={`${IPFS_GATEWAY_URL}${ZHERO_IMG_CID}/5.png`}
                                alt="ZERO Hero NFT"
                                className="rounded border border-neutral-100"
                            />
                            <div className="flex flex-col sm:flex-row justify-between">
                                <span className="font-semibold">{`${(amount * ZHERO_PRICE).toFixed(
                                    1,
                                )} ETH`}</span>
                                <span className="font-semibold">{`Wallet: ${
                                    (eth ? Number(eth.formatted) : 0).toFixed(2) || '0.0'
                                } ETH`}</span>
                            </div>
                            <Counter
                                value={amount}
                                setValue={setAmount}
                                min={1}
                                max={ZHERO_MAX - heroes.length}
                                disabled={heroes.length >= ZHERO_MAX}
                            />
                            <TransactionFees gas={usdFormatter(false).format(gasFees.usd)} />
                        </div>

                        <Button
                            className="w-full"
                            onClick={handleSubmit}
                            disabled={!isWhitelisted() || heroes.length >= ZHERO_MAX}
                            loading={isRedeeming}
                            loadingText="Redeeming"
                            requiresWallet
                        >
                            {isWhitelisted()
                                ? heroes.length >= ZHERO_MAX
                                    ? 'Reached Mint Limit'
                                    : 'Redeem'
                                : 'Not on Whitelist'}
                        </Button>
                    </Card>
                </GridLayout>
            </div>

            <CongratsModal trigger={isSuccess} />
        </>
    );
}

export { ZeroHero };
