import React, { ReactNode } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { useWindowDimensions } from '../../hooks';
import { SectionLayout } from './section';
import {
    MdAccountBalanceWallet,
    MdSwapHorizontalCircle,
    MdLock,
    MdHistoryToggleOff,
    MdFaceRetouchingNatural,
} from 'react-icons/md';
import { IconType } from 'react-icons';
import { Copyright, Socials } from '../base';

type IDashboardLayout = {
    children: ReactNode;
};

const DashboardTemplate = ({ children }: IDashboardLayout) => {
    const { width, breakpoint } = useWindowDimensions();
    const navigate = useNavigate();
    const { pathname } = useLocation();

    const dashboardItems: { name: string; icon: IconType; link: `/${string}` }[] = [
        {
            name: 'Heroes',
            icon: MdFaceRetouchingNatural,
            link: '/zero-hero',
        },
        { name: 'Stake', icon: MdLock, link: '/stake' },
        { name: 'Bridge', icon: MdSwapHorizontalCircle, link: '/bridge' },
        { name: 'Portfolio', icon: MdAccountBalanceWallet, link: '/portfolio' },
    ];

    const renderHeader = (pathname: string) => {
        if (pathname.includes('/')) {
            const split = pathname.split('/');
            const formatted = split[1].replaceAll('-', ' ');
            return { title: formatted, desc: '' };
        } else return { title: '', desc: '' };
    };

    const isActive = (_item: string) => {
        const item = _item.toLowerCase();
        return !item.includes('hero');
    };

    if (width > breakpoint) {
        return (
            <div className="flex h-[calc(100vh-78px)] lg:h-[calc(100vh-102px)] xl:h-[calc(100vh-108px)]">
                <div className="min-w-[180px] lg:min-w-[200px] xl:min-w-[240px] bg-neutral-800 rounded-tr-2xl py-4 md:py-5 lg:py-6 mt-4 md:mt-5 lg:mt-6 pr-6 flex flex-col justify-between">
                    <ul className="flex flex-col gap-2 w-full">
                        {dashboardItems.map((item, i) => (
                            <li
                                key={`dashboard-item-${i}`}
                                onClick={(e) => (isActive(item.name) ? {} : navigate(item.link))}
                                className={`py-2 pl-6 lg:pl-8 xl:pl-10 w-full rounded-r-xl animation duration-150 ${
                                    pathname.includes(item.link.toLowerCase())
                                        ? '!bg-brand-900 !text-white'
                                        : ''
                                } ${
                                    isActive(item.name)
                                        ? 'cursor-not-allowed !text-neutral-400'
                                        : 'cursor-pointer hover:bg-brand-900 hover:bg-opacity-20'
                                }`}
                            >
                                <div className="flex items-center gap-2 lg:gap-3">
                                    <item.icon size="18px" />
                                    {item.name}
                                </div>
                            </li>
                        ))}
                    </ul>
                    <div className="flex flex-col gap-3 justify-center items-center">
                        <Socials />
                        <Copyright center />
                    </div>
                </div>
                <div className="max-h-[calc(100vh-85px)] xl:max-h-[calc(100vh-102px)] overflow-y-auto w-full flex justify-center">
                    <SectionLayout
                        style="min-w-[calc(100vw-210px)] lg:min-w-[calc(100vw-220px)] xl:min-w-[calc(100vw-280px)]"
                        h1Title
                        title={renderHeader(pathname).title}
                        description={renderHeader(pathname).desc}
                        vertical
                        back={pathname.split('/').length === 3 ? '/stake' : ''}
                    >
                        {children}
                    </SectionLayout>
                </div>
            </div>
        );
    } else {
        return (
            <div className="flex flex-col">
                <SectionLayout
                    style="mb-24"
                    h1Title
                    title={renderHeader(pathname).title}
                    description={renderHeader(pathname).desc}
                    vertical
                    back={pathname.split('/').length === 3 ? '/stake' : ''}
                >
                    {children}
                    <div className="flex flex-col gap-3 justify-center items-center mt-10">
                        <Socials />
                        <Copyright center />
                    </div>
                </SectionLayout>
                <SectionLayout
                    style="bg-neutral-800 fixed bottom-0 w-full"
                    display="block"
                    yPadding="py-0"
                    wide
                >
                    <ul className="grid grid-cols-4">
                        {dashboardItems.map((item, i) => (
                            <li
                                key={`dashboard-item-${i}`}
                                onClick={(e) => (isActive(item.name) ? {} : navigate(item.link))}
                                className={`text-center text-lg cursor-pointer w-full ${
                                    pathname.includes(item.link.toLowerCase())
                                        ? 'text-brand-100'
                                        : ''
                                } ${
                                    isActive(item.name)
                                        ? 'cursor-not-allowed !text-neutral-400'
                                        : 'cursor-pointer'
                                }`}
                            >
                                <div className="flex flex-col gap-1 items-center text-xs py-3">
                                    <item.icon size="24px" />
                                    <span className="">{item.name}</span>
                                </div>
                            </li>
                        ))}
                    </ul>
                </SectionLayout>
            </div>
        );
    }
};

export { DashboardTemplate };
