import { useQuery, gql, ApolloError, OperationVariables } from '@apollo/client';

type IQueryProps = {
    pool?: {
        token0Price?: string;
    };
};

type IUsePricesProps = {
    eth: string | undefined;
    loading: boolean;
    error: ApolloError | undefined;
};

export const usePrices = (): IUsePricesProps => {
    const ETH_USDC = gql`
        query GetExchangeRates {
            pool(id: "0x8ad599c3a0ff1de082011efddc58f1908eb6e6d8") {
                token0Price
            }
        }
    `;

    const { data, loading, error } = useQuery<IQueryProps, OperationVariables>(ETH_USDC);

    return {
        eth: data?.pool?.token0Price,
        loading,
        error,
    };
};
