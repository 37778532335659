import { BigNumberish } from 'ethers';
import { FormEvent, useState } from 'react';

export const useTokenInput = () => {
    const [value, setValue] = useState('');

    const onChange = (e: FormEvent<HTMLInputElement>) => setValue(e.currentTarget.value);

    const maxToggle = (balance?: string) => {
        if (!balance) return;
        value === balance ? setValue('') : setValue(balance);
    };

    const isValid = (balance?: string) => {
        if (balance) {
            if (parseFloat(balance) >= parseFloat(value)) {
                return true;
            } else {
                return false;
            }
        }
        if (value) return true;
        return false;
    };

    return {
        maxToggle,
        onChange,
        value,
        setValue,
        isValid,
    };
};
