import React from 'react';
import { Id, toast } from 'react-toastify';
import { ToastStatus } from '../ui/components';
import { BsFillCheckCircleFill, BsFillExclamationCircleFill } from 'react-icons/bs';

export const defaultToastOptions = {
    isLoading: false,
    autoClose: 6000,
    closeButton: true,
};

export const updateToast = (toastId: Id, hash: string, type: 'success' | 'error', msg?: string) => {
    if (type === 'success') {
        toast.update(toastId, {
            render: <ToastStatus status="success" transaction={hash} />,
            type: 'success',
            progressStyle: {
                background: '#41a75b',
            },
            icon: <BsFillCheckCircleFill size="20px" color="#41a75b" />,
            ...defaultToastOptions,
        });
    } else {
        toast.update(toastId, {
            render: (
                <ToastStatus
                    status="error"
                    transaction={hash}
                    message={`${String(msg).slice(0, 100)}...`}
                />
            ),
            type: 'error',
            progressStyle: {
                background: '#db4343',
            },
            icon: <BsFillExclamationCircleFill size="20px" color="#db4343" />,
            ...defaultToastOptions,
        });
    }
};
