import { Transition } from '@headlessui/react';
import React from 'react';
import { useHeroes } from '../../api/use-heroes';
import { IPFS_GATEWAY_URL, ZHERO_IMG_CID } from '../../utils/constants';

type IHeroGalleryProps = {
    title?: string;
    size?: string;
    center?: boolean;
    gap?: `gap-${number}`;
};

export const HeroGallery = ({ title, size, center, gap }: IHeroGalleryProps) => {
    const { heroes } = useHeroes();

    return (
        <Transition
            show={heroes.length > 0}
            enter="transition-opacity duration-75"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity duration-150"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
        >
            {title && <h3 className="text-sm font-semibold mb-2">{title}</h3>}
            <div
                className={`flex flex-wrap ${gap ? gap : 'gap-2 md:gap-4'} ${
                    center ? 'justify-center' : ''
                }`}
            >
                {heroes.map((id) => (
                    <img
                        key={id}
                        src={`${IPFS_GATEWAY_URL}${ZHERO_IMG_CID}/${heroes[id]}.png`}
                        alt="Zero Hero Avatar"
                        className={`rounded-sm`}
                        height={size || '100'}
                        width={size || '100'}
                    />
                ))}
            </div>
        </Transition>
    );
};
