import React, { createContext, ReactNode, useContext, useEffect, useState } from 'react';
import { useHeroes } from '../api';
// Types
export type IUIStoreProps = {
    activeHero?: number;
    setActiveHero?: React.Dispatch<React.SetStateAction<any>>;
};

// Context
const UIContext = createContext<IUIStoreProps>({
    setActiveHero() {},
});

// Wrapper
export function UIStore(props: { children: ReactNode }) {
    const { heroes } = useHeroes();
    const [activeHero, setActiveHero] = useState();

    useEffect(() => {
        if (heroes?.length > 0) setActiveHero(heroes[0]);
    }, [heroes]);

    return (
        <UIContext.Provider
            value={{
                activeHero,
                setActiveHero,
            }}
        >
            {props.children}
        </UIContext.Provider>
    );
}

// Independent
export function useUIContext() {
    return useContext(UIContext);
}
