import { BigNumber, constants } from 'ethers';

export const VAULTS_TABLE_DATA = [
    {
        id: 0,
        name: 'ZERO',
        tvl: BigNumber.from('0'),
        apy: BigNumber.from('0'),
        address: constants.AddressZero, // TODO
    },
];

export const VAULTS_TABLE_COLUMNS = [
    {
        name: 'name',
        label: 'Vault',
        options: {
            filter: false,
            sort: true,
            sortThirdClickReset: true,
        },
    },
    {
        name: 'tvl',
        label: 'TVL',
        options: {
            filter: true,
            sort: true,
            sortThirdClickReset: true,
        },
    },
    {
        name: 'apy',
        label: 'APY',
        options: {
            filter: true,
            sort: true,
            sortThirdClickReset: true,
        },
    },
    {
        name: 'address',
        label: 'Address',
        options: {
            filter: false,
            sort: false,
            display: false,
        },
    },
    {
        name: 'id',
        label: 'ID',
        options: {
            filter: false,
            sort: false,
            display: false,
        },
    },
    {
        name: '',
        label: '',
        options: {
            filter: false,
        },
    },
];
