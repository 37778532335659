import '@rainbow-me/rainbowkit/styles.css';
import {
    connectorsForWallets,
    darkTheme,
    getDefaultWallets,
    RainbowKitProvider,
} from '@rainbow-me/rainbowkit';
import { configureChains, createClient, WagmiConfig } from 'wagmi';
import { publicProvider } from 'wagmi/providers/public';
import { Web3Provider, JsonRpcProvider } from '@ethersproject/providers';
import { Signer } from '@ethersproject/abstract-signer';
import { Provider } from '@wagmi/core';
import { hardhat, mainnet, goerli } from 'wagmi/chains';
import { TESTING } from './constants';
import merge from 'lodash.merge';
import {
    coinbaseWallet,
    injectedWallet,
    metaMaskWallet,
    rainbowWallet,
    trustWallet,
    walletConnectWallet,
    omniWallet,
    imTokenWallet,
    argentWallet,
} from '@rainbow-me/rainbowkit/wallets';
import { braveWallet } from '@rainbow-me/rainbowkit/wallets';
import { ledgerWallet } from '@rainbow-me/rainbowkit/wallets';

export const { chains, provider } = configureChains(
    [TESTING ? hardhat : mainnet, goerli],
    [publicProvider()],
);

export const connectors = connectorsForWallets([
    {
        groupName: 'Recommended',
        wallets: [metaMaskWallet({ chains })],
    },
    {
        groupName: 'Popular',
        wallets: [
            coinbaseWallet({ appName: 'zeroDAO App', chains }),
            rainbowWallet({ chains }),
            trustWallet({ chains }),
            ledgerWallet({ chains }),
            walletConnectWallet({ chains }),
            imTokenWallet({ chains }),
            braveWallet({ chains }),
            injectedWallet({ chains }),
        ],
    },
]);

export const wagmiClient = createClient({
    autoConnect: true,
    connectors,
    provider,
});

export const walletTheme = merge(
    darkTheme({
        borderRadius: 'small',
        accentColor: '#41a75b',
    }),
    {
        colors: {
            modalBackground: '#212121',
        },
    },
);

export interface IWalletState {
    provider?: Web3Provider | JsonRpcProvider | Provider;
    address?: string;
    signer?: Signer;
    isLoading: boolean;
    error: null | string;
}

export { WagmiConfig, RainbowKitProvider };
