import React, { Dispatch, Fragment, ReactNode, SetStateAction } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { BiChevronDown } from 'react-icons/bi';
import { Button } from './button';

type IDropdownProps = {
    children?: ReactNode;
    items?: string[];
    button?: string | ReactNode;
    color?: 'transparent' | 'green' | 'red' | 'black';
    buttonClass?: string;
    disabled?: boolean;
    menuClass?: string;
    setState?: Dispatch<SetStateAction<string>>;
};

export const Dropdown = ({
    children,
    items,
    button,
    buttonClass,
    menuClass,
    disabled,
    color = 'green',
    setState,
}: IDropdownProps) => {
    return (
        <Menu as="div" className={`relative inline-block text-left leading-tight`}>
            <Menu.Button>
                <Button
                    color={color}
                    className={`${buttonClass} !shadow-none`}
                    disabled={disabled}
                    as="div"
                >
                    {button}
                    {items && items?.length > 1 && !children && (
                        <BiChevronDown size="20px" aria-hidden="true" />
                    )}
                </Button>
            </Menu.Button>

            <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
            >
                <Menu.Items
                    className={`${menuClass} absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-neutral-700 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none`}
                >
                    {items?.length !== 0 && !children ? (
                        <div className="flex flex-col">
                            {items?.map((item, i) => (
                                <Menu.Item key={`dropdown-${item}-${i}`}>
                                    {({ active }) => (
                                        <button
                                            onClick={() => (setState ? setState(item) : {})}
                                            className={`py-1 px-2 text-right font-semibold transition duration-150 hover:bg-brand-100 ${
                                                i === 0 ? 'rounded-t-md' : ''
                                            } ${i === items.length - 1 ? 'rounded-b-md' : ''}`}
                                        >
                                            {item}
                                        </button>
                                    )}
                                </Menu.Item>
                            ))}
                        </div>
                    ) : (
                        <Menu.Item>
                            <div className="py-2 px-3">{children}</div>
                        </Menu.Item>
                    )}
                </Menu.Items>
            </Transition>
        </Menu>
    );
};
