import React from 'react';
import { useWindowDimensions } from '../../hooks';
import { WalletButton } from '../features';
import { SectionLayout } from '../layout';
import { Logo } from './logo';

export const Navbar = () => {
    const { width, breakpoint } = useWindowDimensions();

    return (
        <nav className="w-full z-[99999] bg-neutral-800 lg:mb-4">
            <SectionLayout
                yPadding="py-2 md:py-3 lg:py-4"
                wide
                style="min-h-[68px] md:min-h-[84px] lg:min-h-[92px]"
            >
                <div className="flex flex-wrap justify-between items-center w-full">
                    <div>
                        <a href="/">
                            <Logo xl={width > breakpoint} />
                        </a>
                    </div>

                    <WalletButton hero />
                </div>
            </SectionLayout>
        </nav>
    );
};
