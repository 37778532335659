import React, { ReactNode } from 'react';
import { useWindowDimensions } from '../../hooks';
import { BiArrowBack } from 'react-icons/bi';
import { useNavigate } from 'react-router';

type ISectionProps = {
    title?: string;
    description?: string | ReactNode;
    yPadding?: string;
    children: ReactNode;
    wide?: boolean;
    verticalCenter?: boolean;
    fullHeight?: boolean;
    vertical?: boolean;
    style?: string;
    h1Title?: boolean;
    display?: 'block' | 'grid' | 'flex';
    back?: string;
};

const SectionLayout = (props: ISectionProps) => {
    const { width } = useWindowDimensions();
    const navigate = useNavigate();
    return (
        <div
            className={`
      ${props.yPadding ? props.yPadding : props.verticalCenter ? '0' : 'py-6 md:py-8 lg:py-10'}
      ${props.verticalCenter ? 'flex items-center' : ''}
      ${props.fullHeight ? 'h-full' : ''}
      ${props.vertical ? 'flex-col' : ''}
      ${props.style ? props.style : ''}
      px-4 lg:px-8 ${props.display ? props.display : 'flex'}
    `}
        >
            {props.back && (
                <div className="absolute">
                    <button
                        className="flex items-center gap-2 transition duration-150 hover:text-brand-900 p-4 pl-0 pt-0"
                        onClick={() => navigate(props.back || '')}
                    >
                        <BiArrowBack size="20px" />
                        {width > 600 && 'Back'}
                    </button>
                </div>
            )}
            {(props.title || props.description) && (
                <div className="mb-8 md:mb-10 text-center">
                    {props.title && (
                        <>
                            {props.h1Title ? (
                                <h1 className="text-2xl md:text-3xl font-bold uppercase">
                                    {props.title}
                                </h1>
                            ) : (
                                <h2 className="text-2xl md:text-3xl font-bold">{props.title}</h2>
                            )}
                        </>
                    )}
                    {props.description && (
                        <div
                            className={`mt-2 md:mt-4 text-lg md:text-xl md:px-20 transition duration-150 ${
                                typeof props.description === 'object' ? 'hover:text-brand-100' : ''
                            }`}
                        >
                            {props.description}
                        </div>
                    )}
                </div>
            )}
            {props.children}
        </div>
    );
};

export { SectionLayout };
