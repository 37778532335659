import React, { ReactNode } from 'react';
import { AiFillWarning } from 'react-icons/ai';
import { useWindowDimensions } from '../../hooks';

type ICardProps = {
    children: ReactNode;
    className?: string;
    header?: string | ReactNode;
    inner?: boolean;
    color?: `bg-${string}`;
    construction?: boolean;
    maxWidth?: boolean;
};

type ICardHeaderProps = {
    children: ReactNode | string;
};

export const Card = (props: ICardProps) => {
    const { width, breakpoint } = useWindowDimensions();
    const padding = 'py-4 px-3 md:px-4 md:py-4 lg:px-6';
    if (props.construction) {
        return (
            <>
                <div
                    className={`absolute w-screen h-[calc(100vh-300px)] md:w-[calc(100vw-200px)] lg:w-[calc(100vw-300px)] max-h-[600px] flex justify-center items-center ${
                        width > breakpoint ? '' : 'left-0'
                    }`}
                >
                    <div className="flex flex-col justify-center items-center gap-2">
                        <AiFillWarning size="72px" />
                        <span className="text-lg">Under Construction</span>
                    </div>
                </div>
                <div className={`opacity-30 bg-neutral-800 rounded-lg mx-auto pointer-events-none`}>
                    <div
                        className={`${props.maxWidth ? 'max-w-[500px]' : ''}
          ${padding} ${props.color ? props.color : 'bg-brand-card'} rounded-lg ${
                            props.inner
                                ? 'shadow-inner shadow-[#0a0a0a] bg-neutral-900'
                                : 'shadow-[0px_2px_4px_-1px_rgb(0_0_0_/_20%)]'
                        }
          ${props.className ? props.className : ''}
        `}
                    >
                        {props.header && (
                            <div className="flex justify-between items-baseline">
                                {props.header}
                            </div>
                        )}
                        {props.children}
                    </div>
                </div>
            </>
        );
    } else {
        return (
            <div
                className={`${props.maxWidth ? 'max-w-[500px]' : ''}
          ${padding} ${props.color ? props.color : 'bg-brand-card'} rounded-lg ${
                    props.inner
                        ? 'shadow-inner shadow-[#0a0a0a] bg-neutral-900'
                        : 'shadow-[0px_2px_4px_-1px_rgb(0_0_0_/_20%)]'
                }
          ${props.className ? props.className : ''}
        `}
            >
                {props.header && (
                    <div className="flex justify-between items-baseline">{props.header}</div>
                )}
                {props.children}
            </div>
        );
    }
};

export const CardHeader = ({ children }: ICardHeaderProps) => (
    <div className="flex items-center justify-between mb-4">
        {typeof children === 'string' ? (
            <h4 className="text-xl font-bold">{children}</h4>
        ) : (
            children
        )}
    </div>
);
