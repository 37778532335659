import React from 'react';

export type IInputErrorProps = 'invalid' | 'max' | '';

type IInputProps = {
    type?: 'number' | 'percent' | 'string' | 'token';
    onChange: any;
    value: string;
    placeholder?: string;
    size?: '3xl' | '2xl' | 'xl' | 'lg' | 'md';
    title?: string;
    tooltip?: string;
    required?: boolean;
    className?: string;
    boxClassName?: string;
    max?: string;
    allowMax?: boolean;
};

export const Input = ({
    type = 'string',
    onChange,
    value,
    placeholder,
    size,
    title,
    tooltip,
    required,
    className,
    boxClassName,
    max,
    allowMax,
}: IInputProps) => {
    const textSize = () => {
        switch (size) {
            case '3xl':
                return 'text-3xl !py-2 !px-4';
            case '2xl':
                return 'text-2xl';
            case 'xl':
                return 'text-xl';
            case 'lg':
                return 'text-lg';
            default:
                return 'text-md';
        }
    };

    const baseClass = 'w-full py-1 px-2 rounded-lg';

    return (
        <div className={`flex flex-col ${boxClassName}`}>
            {title && (
                <h3 className="mb-1">
                    {title}
                    {required && <span className="text-red-500 ml-1">*</span>}
                </h3>
            )}
            <div className="w-full flex items-center gap-2">
                <input
                    placeholder={placeholder}
                    value={value}
                    onChange={onChange}
                    type={'text'}
                    step={type === 'percent' ? '0.1' : '1'}
                    className={`${baseClass} ${textSize()} ${className}`}
                    onKeyDown={(event) => {
                        if (type !== 'token' && type !== 'number') return;
                        if (event.key === 'Backspace') return;
                        if (max && parseFloat(max) < parseFloat(value)) {
                            !allowMax && event.preventDefault();
                        }
                        //allow only numbers and decimal point
                        if (!event.key.match(/^\d*\.?\d*$/)) event.preventDefault();
                    }}
                />
            </div>
        </div>
    );
};
