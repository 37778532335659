import { BigNumberish, utils } from 'ethers';

export function truncate(text: string, count?: number) {
    let _count: number[];
    if (count) _count = [count, -count];
    else _count = [3, -4];
    return `${text.slice(0, _count[0])}...${text.slice(_count[1])}`;
}

export const usdFormatter = (isCompact = true) =>
    new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        notation: isCompact ? 'compact' : 'standard',
    });

export const percentFormatter = new Intl.NumberFormat('en-US', {
    style: 'percent',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    notation: 'compact',
});

export const bigNumberToString = (number: BigNumberish | undefined, decimals = 18): string => {
    if (!number) {
        console.error('given invalid bignumber');
        return '0';
    }
    return utils.formatUnits(number, decimals);
};

export const limitChars = (string: string, count: number) => {
    if (string && string.length > count) {
        return `${string.slice(0, count)}\u2026`;
    }
};

export const addDollarAmounts = (list: Array<string> | undefined, dollarSign = true) => {
    if (!list) return dollarSign ? `$0` : 0;
    const withoutDollarSign = list.map((el) => parseFloat(el.slice(1).replaceAll(',', '')));
    const sum = withoutDollarSign.reduce((partial, next) => partial + next, 0);
    return dollarSign ? `$${sum.toFixed(2).toString()}` : sum.toFixed(2);
};

export const addStringsInArr = (list: Array<string>) => {
    if (!list) return '0';
    const sum = list.reduce(
        (partialSum, a) => parseFloat(partialSum.toString()) + parseFloat(a.toString()),
        0,
    );
};
