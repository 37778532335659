import React from 'react';
import { Button } from './button';
import { FiPlus, FiMinus } from 'react-icons/fi';

type ICounterProps = {
    value: number;
    setValue: React.Dispatch<React.SetStateAction<number>>;
    min?: number;
    max?: number;
    disabled?: boolean;
};

export const Counter = ({ value, setValue, disabled, max, min = 0 }: ICounterProps) => {
    const handlePlus = () => {
        if (max) {
            if (value < max) setValue(value + 1);
        } else {
            setValue(value + 1);
        }
    };

    const handleMinus = () => {
        if (value > min) setValue(value - 1);
    };

    return (
        <div className="w-full flex justify-between items-center">
            <Button
                onClick={handleMinus}
                color="border"
                className="px-2"
                disabled={value === min || disabled}
            >
                <FiMinus size="20px" />
            </Button>

            <div>
                <span className="font-bold text-lg">{value}</span>
            </div>

            <Button
                onClick={handlePlus}
                color="border"
                className="px-2"
                disabled={value === max || disabled}
            >
                <FiPlus size="20px" />
            </Button>
        </div>
    );
};
