import React from 'react';
import ReactDOM from 'react-dom/client';
import { HashRouter } from 'react-router-dom';
import './styles/main.css';
import App from './App';
import { RainbowKitProvider, WagmiConfig, wagmiClient, chains, walletTheme } from './utils/wallet';
import { TransactionsStore, UserBalancesStore, ApprovedTokensStore, UIStore } from './stores';
import { ApolloProvider } from '@apollo/client';
import { client } from './utils/apollo';

const root = ReactDOM.createRoot(document.getElementById('root') as Element);
root.render(
    <>
        <WagmiConfig client={wagmiClient}>
            <RainbowKitProvider chains={chains} theme={walletTheme}>
                <ApolloProvider client={client}>
                    <UserBalancesStore>
                        <TransactionsStore>
                            <ApprovedTokensStore>
                                <UIStore>
                                    <HashRouter>
                                        <App />
                                    </HashRouter>
                                </UIStore>
                            </ApprovedTokensStore>
                        </TransactionsStore>
                    </UserBalancesStore>
                </ApolloProvider>
            </RainbowKitProvider>
        </WagmiConfig>
    </>,
);
