import { Contract, ethers } from 'ethers';
import { useEffect, useState } from 'react';
import { useContracts } from './use-contracts';

const MERKLE: any = {
    localhost: require('@zerodao/token/merkle/localhost/zero-airdrop.json'),
    mainnet: require('@zerodao/token/merkle/mainnet/zero-airdrop.json'),
};
const merkleTree = MERKLE[process.env.REACT_APP_TESTING ? 'forknet' : 'mainnet'];

function useTokenAirdrop(address: string) {
    const [dataLoading, setDataLoading] = useState(true); // Data retrieval status
    const [numTokens, setNumTokens] = useState(0); // Number of claimable tokens
    const [alreadyClaimed, setAlreadyClaimed] = useState(false); // Claim status
    const { sZeroContract } = useContracts();

    const getAirdropAmount = (address: string): string => {
        const amount = (merkleTree.claims[ethers.utils.getAddress(address)] || { amount: '0' })
            .amount;
        return ethers.utils.formatUnits(
            amount,
            0, // CHANGE: from 18 to 0
        );
    };

    const getClaimedStatus = async (index: string): Promise<boolean> => {
        return await sZeroContract?.isClaimed(index);
    };

    const claimAirdrop = async () => {
        if (!address) {
            throw new Error('Not Authenticated');
        }

        const formattedAddress = ethers.utils.getAddress(address);
        const claim = merkleTree.claims[formattedAddress];
        try {
            const tx = await sZeroContract?.claim(
                claim.index,
                formattedAddress,
                claim.amount,
                claim.proof,
            );
            console.log(tx);
            await tx.wait(1);
            await syncStatus();
        } catch (e) {
            console.error('Error when claiming tokens:', e);
        }
    };

    /**
     * After authentication, update number of tokens to claim + claim status
     */
    const syncStatus = async () => {
        setDataLoading(true);
        if (address) {
            const tokens = parseFloat(getAirdropAmount(address));
            setNumTokens(tokens);
            if (tokens > 0) {
                console.log(`Airdrop: ${tokens} ZERO`);
                // const claimed = await getClaimedStatus(merkleResult.claims[address].index);
                setAlreadyClaimed(false);
            } else {
                setAlreadyClaimed(true);
            }
        }
        setDataLoading(false);
    };

    useEffect(() => {
        syncStatus();
    }, [address]);

    return {
        dataLoading,
        numTokens,
        alreadyClaimed,
        claimAirdrop,
    };
}

export { useTokenAirdrop };
