import React, { useEffect } from 'react';
import { useAccount } from 'wagmi';
import { useTokenAirdrop } from '../../api';
import { useModal } from '../../hooks';
import { Button } from '../components';
import { ModalBase } from './base';

export const AirdropModal = () => {
    const { handleClose, open, handleOpen } = useModal();
    const { address } = useAccount();
    const { numTokens, claimAirdrop, alreadyClaimed } = useTokenAirdrop(address || '');

    useEffect(() => {
        if (!alreadyClaimed && numTokens !== 0) {
            const timeout = setTimeout(() => handleOpen(), 1000);
            return () => clearTimeout(timeout);
        }
        if (open && numTokens === 0) handleClose();
    }, [numTokens]);

    return (
        <ModalBase open={open} handleClose={handleClose} title="Airdrop">
            <p className="text-center py-2">
                Congratulations on being chosen to be an initial ZERO token holder! Claim your
                tokens below.
            </p>

            <div className="flex flex-col items-center gap-4 py-6">
                <img
                    className="shadow-brand-black shadow-md rounded-full bg-black"
                    src="/tokens/ZERO.png"
                    alt="zeroDAO Logo"
                    height="180px"
                    width="180px"
                />
                <p className="font-bold text-xl">{numTokens} ZERO</p>
            </div>

            <Button className="w-full mt-4" onClick={claimAirdrop}>
                Claim Tokens
            </Button>
        </ModalBase>
    );
};
