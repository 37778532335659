import React from 'react';
import { Card } from '../components';
import { Skeleton } from '@mui/material';

type ITransactionOutputProps = {
    output?: string;
    apy?: string;
    loading?: boolean;
    asset?: {
        name: string;
        address?: string;
    };
    accumalated?: string;
};

export const TransactionOutput = ({
    output = '0.0',
    loading,
    apy,
    asset = { name: 'ETH' },
    accumalated,
}: ITransactionOutputProps) => {
    const createFeeList = () => {
        const list = [];
        if (apy) list.push({ text: 'APY', output: apy });
        if (accumalated)
            list.push({
                text: 'Rewards',
                output: `${accumalated}${asset ? ` ${asset.name}` : ''}`,
            });
        list.push({ text: 'Expected Output', output: `${output}${asset ? ` ${asset.name}` : ''}` });
        return list;
    };

    return (
        <Card
            color="bg-brand-neon"
            className={`flex flex-col origin-top mt-2 text-neutral-800 font-medium`}
        >
            {createFeeList().map((el, i) => (
                <div key={`fee-details-${i}`} className="flex items-center justify-between">
                    <span>{el.text}</span>
                    {loading ? (
                        <Skeleton variant="rounded" height="24px" width="96px" />
                    ) : (
                        <span>{`${el.output}`}</span>
                    )}
                </div>
            ))}
        </Card>
    );
};
