import { useContract, useProvider } from 'wagmi';
import { NETWORK } from '../utils/constants';
import { CHAIN_CONFIGURATIONS, DEPLOYMENTS } from './common';
import { ZeroHeroNFT } from '@zerodao/token/typechain-types';
import { Contract } from 'ethers';

type IUseContractProps = {
    sZeroContract: Contract | null;
    zeroContract: Contract | null;
    zeroHeroContract: ZeroHeroNFT | Contract | null;
};

export const useContracts = (): IUseContractProps => {
    const provider = useProvider({
        chainId: CHAIN_CONFIGURATIONS[NETWORK].chainId,
    });

    const sZeroContract = useContract({
        address: DEPLOYMENTS.sZero.address,
        abi: DEPLOYMENTS.sZero.abi,
        signerOrProvider: provider,
    });

    const zeroContract = useContract({
        address: DEPLOYMENTS.zero.address,
        abi: DEPLOYMENTS.zero.abi,
        signerOrProvider: provider,
    });

    const zeroHeroContract = useContract({
        address: DEPLOYMENTS.zeroHero.address,
        abi: DEPLOYMENTS.zeroHero.abi,
        signerOrProvider: provider,
    }) as ZeroHeroNFT;

    return {
        sZeroContract,
        zeroContract,
        zeroHeroContract,
    };
};
