import { BigNumber, ethers, utils } from 'ethers';
import { useEffect, useState } from 'react';
import { useAccount, useFeeData, useNetwork, useSigner } from 'wagmi';
import { useUserBalancesContext } from '../stores';
import { NETWORK, ZHERO_PRICE, ZHERO_WHITELISTED, ZHERO_PRIVATELISTED } from '../utils/constants';
import { CHAIN_CONFIGURATIONS } from './common';
import { useContracts } from './use-contracts';
import { usePrices } from './use-prices';

export const useHeroes = () => {
    const prices = usePrices();
    const { data } = useFeeData();
    const [gasFees, setGasFees] = useState({ usd: 0, eth: '0' });
    const [apy, setApy] = useState(0);
    const [rewards, setRewards] = useState('0');
    const { zeroHeroContract } = useContracts();
    const { data: signer } = useSigner();
    const { address } = useAccount();
    const [isLoading, setIsLoading] = useState(false);
    const { heroes, eth } = useUserBalancesContext();
    const { chain } = useNetwork();
    const correctNetwork = chain?.id === CHAIN_CONFIGURATIONS[NETWORK].chainId;

    // Utils
    const isWhitelisted = () => {
        if (!address) return false;
        if (Object.keys(ZHERO_WHITELISTED).includes(address)) return true;
        return false;
    };
    const isPrivatelisted = () => {
        if (!address) return false;
        if (Object.keys(ZHERO_PRIVATELISTED).includes(address)) return true;
        return false;
    };

    // Actions
    const redeem = async (amount: number, blocker?: boolean) => {
        if (amount === 0 || blocker || !signer || !zeroHeroContract || !correctNetwork) return;
        setIsLoading(true);
        const amountToSend = ethers.utils.parseEther((amount * ZHERO_PRICE).toString());
        let tx;
        try {
            if (isPrivatelisted() && address) {
                tx = await zeroHeroContract
                    ?.connect(signer)
                    .privateMint(
                        ZHERO_PRIVATELISTED[address].index,
                        address,
                        ZHERO_PRIVATELISTED[address].amount,
                        ZHERO_PRIVATELISTED[address].proof,
                        amount,
                        { value: amountToSend },
                    );
            } else if (isWhitelisted() && address) {
                tx = await zeroHeroContract
                    ?.connect(signer)
                    .whitelistMint(
                        ZHERO_WHITELISTED[address].index,
                        address,
                        ZHERO_WHITELISTED[address].amount,
                        ZHERO_WHITELISTED[address].proof,
                        amount,
                        { value: amountToSend },
                    );
            } else {
                tx = await zeroHeroContract?.connect(signer).mint(amount, { value: amountToSend });
            }
            setIsLoading(false);
            return tx;
        } catch {
            setIsLoading(false);
        }
    };

    const withdraw = async (amount: number, blocker?: boolean) => {
        if (amount === 0 || blocker || !signer || !zeroHeroContract || !correctNetwork) return;
        const tx = await zeroHeroContract?.connect(signer).withdraw();
        console.log('Withdrew:', tx);
        return tx;
    };

    // Estimations
    const estimateGasFees = async (
        amount: number,
        type: 'mint' | 'withdraw',
        blocker?: boolean,
    ) => {
        let output = { usd: 0, eth: '0' };
        if (
            !zeroHeroContract ||
            !signer ||
            blocker ||
            heroes.length >= 5 ||
            !correctNetwork ||
            parseFloat(eth?.formatted || '') <= 0.3
        )
            return output;
        const isPrivate = await zeroHeroContract?.isPrivateActive();
        const isWhitelist = await zeroHeroContract?.isWhitelistActive();
        const amountToSend = ethers.utils.parseEther((amount * ZHERO_PRICE + 0.1).toString());

        let ethEstimate;
        if (type === 'mint' && amount > 0) {
            if (isPrivate && address) {
                if (!isPrivatelisted()) ethEstimate = BigNumber.from('0');
                else {
                    ethEstimate = await zeroHeroContract
                        ?.connect(signer)
                        .estimateGas.privateMint(
                            ZHERO_PRIVATELISTED[address].index,
                            address,
                            ZHERO_PRIVATELISTED[address].amount,
                            ZHERO_PRIVATELISTED[address].proof,
                            amount,
                            { value: amountToSend },
                        );
                }
            } else if (isWhitelist && address) {
                if (!isWhitelisted()) ethEstimate = BigNumber.from('0');
                else {
                    ethEstimate = await zeroHeroContract
                        ?.connect(signer)
                        .estimateGas.whitelistMint(
                            ZHERO_WHITELISTED[address].index,
                            address,
                            ZHERO_WHITELISTED[address].amount,
                            ZHERO_WHITELISTED[address].proof,
                            amount,
                            { value: amountToSend },
                        );
                }
            } else {
                ethEstimate = await zeroHeroContract
                    ?.connect(signer)
                    .estimateGas.mint(amount, { value: amountToSend });
            }
        }
        if (type === 'withdraw' && amount > 0) {
            ethEstimate = await zeroHeroContract?.connect(signer).estimateGas.withdraw();
        }
        ethEstimate = utils.formatEther(ethEstimate?.mul(data?.gasPrice || '0') || '0');
        output = {
            usd: parseFloat(ethEstimate) * parseFloat(prices?.eth || '0'),
            eth: ethEstimate,
        };
        setGasFees(output);
        return output;
    };

    const checkHeroAmount = async () => {
        if (address && zeroHeroContract) {
            const balance = await zeroHeroContract.balanceOf(address);
            const formatted = Number(ethers.utils.formatEther(balance));
            return formatted;
        }
        return 0;
    };

    const estimateRewards = async (address?: string) => {
        if (!zeroHeroContract || !signer || !address) return '0';
        let reward: any;
        return reward;
    };

    // Get gas fees on mount
    useEffect(() => {
        const getter = async () => {
            await estimateGasFees(1, 'mint');
        };
        getter();
    }, [signer]);

    return {
        withdraw,
        redeem,
        gasFees,
        getGasFees: estimateGasFees,
        apy,
        getRewards: estimateRewards,
        rewards,
        checkHeroAmount,
        isWhitelisted,
        isRedeeming: isLoading,
        heroes,
    };
};
