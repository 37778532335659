import { memoize } from 'lodash';
import { Contract, ethers } from 'ethers';
import { IAvailableChains, IAvailableContracts } from './types';
import { NETWORK } from '../utils/constants';

export const DEFAULT_GAS_LIMIT = 8000000;

const determineDirectory = () => {
    if (NETWORK === 'ETHEREUM') return 'mainnet';
    else return NETWORK.toLowerCase();
};

export const RPC_ENDPOINTS: Record<IAvailableChains, any> = {
    ETHEREUM: 'https://mainnet.infura.io/v3/816df2901a454b18b7df259e61f92cd2',
    GOERLI: 'https://eth-goerli.blastapi.io/c924041f-6cfe-4fe0-8f69-da7240bb525b',
    LOCALHOST: '',
};

export const DEPLOYMENTS: Record<IAvailableContracts, any> = {
    sZero: require(`@zerodao/token/deployments/${determineDirectory()}/sZERO`),
    zero: require(`@zerodao/token/deployments/${determineDirectory()}/ZERO`),
    zeroHero: require(`@zerodao/token/deployments/${determineDirectory()}/ZeroHeroNFT`),
};

export const CHAIN_CONFIGURATIONS = {
    MATIC: {
        chainId: 137,
        chainName: 'Polygon',
        nativeCurrency: {
            symbol: 'MATIC',
            name: 'MATIC',
            decimals: 18,
        },
        rpcUrls: ['https://rpc-mainnet.maticvigil.com/'],
        blockExplorerUrls: ['https://explorer-mainnet.maticvigil.com/'],
    },
    ARBITRUM: {
        chainId: 42161,
        chainName: 'Arbitrum',
        nativeCurrency: {
            symbol: 'AETH',
            name: 'Ether',
            decimals: 18,
        },
        rpcUrls: ['https://arb1.arbitrum.io/rpc'],
        blockExplorerUrls: ['https://arbiscan.io'],
    },
    ETHEREUM: {
        chainId: 1,
    },
    LOCALHOST: {
        chainId: 31337,
    },
    GOERLI: {
        chainId: 5,
    },
};

export const getProvider = memoize((chain: IAvailableChains) => {
    return new ethers.providers.JsonRpcProvider(RPC_ENDPOINTS[chain]);
});

export const getContractName = async (contract: Contract) => {
    if (!contract) return;
    let name = await contract.name();
    return name;
};

export const getContractBalance = async (contract: Contract) => {
    if (!contract) return;
    let balance = await contract.balance();
    balance = balance.toNumber() / 8;
    balance = balance.toFixed(2);
    return balance;
};

export const getContractAddress = (contract?: Contract | null) => {
    if (!contract) return ethers.constants.AddressZero;
    return ethers.utils.getAddress(contract.address);
};
