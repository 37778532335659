import { Transition } from '@headlessui/react';
import React from 'react';
import { BiLoaderAlt } from 'react-icons/bi';

type ISpinnerLoader = {
    loading?: boolean;
    size?: '16px' | '20px' | '24px' | '30px' | '36px';
};

export const SpinnerLoader = ({ loading, size = '16px' }: ISpinnerLoader) =>
    loading ? (
        <Transition
            show={loading}
            enter="transition-opacity duration-75"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity duration-150"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
        >
            <BiLoaderAlt size={size} className="animate-spin-fast" />
        </Transition>
    ) : (
        <></>
    );
