import React, { createContext, ReactNode, useContext, useEffect, useState } from 'react';
import { useAccount } from 'wagmi';
import { TESTING } from '../utils/constants';

// Types
export type IApprovedTokensStoreProps = {
    approved: string[];
    updateApproved: (token: string) => void;
};

// Context
const ApprovedTokensContext = createContext<IApprovedTokensStoreProps>({
    approved: [],
    updateApproved(token) {},
});

// Wrapper
export function ApprovedTokensStore(props: { children: ReactNode }) {
    const { address } = useAccount();
    const [approved, setApproved] = useState<Array<string>>([]);

    const updateApproved = (token: string) => {
        if (approved?.includes(token) || !address) return;

        let shallow: string[] = [];
        const localStore = TESTING ? '' : localStorage.getItem('approvedTokens');
        if (localStore) {
            const formatted = JSON.parse(localStore);
            shallow = [...formatted[address]];
            shallow.push(token);
            localStorage.setItem(
                'approvedTokens',
                JSON.stringify({ [address]: shallow, ...formatted }),
            );
        } else {
            shallow = approved && approved.length !== 0 ? [...approved] : [];
            shallow.push(token);
            localStorage.setItem('approvedTokens', JSON.stringify({ [address]: shallow }));
        }
        setApproved(shallow);
    };

    useEffect(() => {
        const localStore = localStorage.getItem('approvedTokens');
        if (localStore) {
            const formatted = JSON.parse(localStore);
            if (formatted && address && Object.keys(formatted).includes(address))
                setApproved(formatted[address]);
            else setApproved([]);
        }
    }, [address]);

    useEffect(() => {
        if (TESTING) localStorage.setItem('approvedTokens', JSON.stringify({}));
    }, []);

    return (
        <ApprovedTokensContext.Provider
            value={{
                approved,
                updateApproved,
            }}
        >
            {props.children}
        </ApprovedTokensContext.Provider>
    );
}

// Independent
export function useApprovedTokensContext() {
    return useContext(ApprovedTokensContext);
}
