import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { StakeZero, ZeroBridge, ZeroHero } from './ui/views';
import { Footer, Navbar } from './ui/base';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { DashboardTemplate } from './ui/layout';
// import { AirdropModal } from './ui/modals';

function App() {
    return (
        <>
            <Navbar />
            <DashboardTemplate>
                <Routes>
                    <Route path="/" element={<Navigate replace to="/zero-hero" />} />
                    <Route path="/stake" element={<StakeZero />} />
                    <Route path="/zero-hero" element={<ZeroHero />} />
                    <Route path="/bridge" element={<ZeroBridge />} />
                    {/* <Route path="/portfolio" element={<Portfolio />} />
                    <Route path="/vaults" element={<Vaults />} />
                    <Route path="/vaults/:id" element={<VaultDetails />} /> */}
                    \
                    <Route path="*" element={<Navigate replace to="/zero-hero" />} />
                </Routes>
            </DashboardTemplate>
            <Footer />

            <ToastContainer
                position="bottom-right"
                autoClose={6000}
                hideProgressBar={false}
                newestOnTop={false}
                rtl={false}
                draggable
                pauseOnHover
                theme="dark"
                toastClassName="!bg-neutral-800"
            />

            {/* <AirdropModal /> */}
        </>
    );
}

export default App;
