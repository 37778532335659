import React, { useEffect, useState } from 'react';
import { Button } from '../components';
import { HeroGallery } from '../features/hero-gallery';
import { ModalBase } from './base';

type ICongratsModalProps = {
    trigger: boolean;
};

export const CongratsModal = ({ trigger }: ICongratsModalProps) => {
    const [open, setOpen] = useState(false);

    useEffect(() => {
        if (trigger) setOpen(true);
    }, [trigger]);

    return (
        <ModalBase open={open} handleClose={() => setOpen(false)} title="You're a ZERO HERO">
            <p className="text-center py-2">
                Congratulations on becoming a ZERO Hero! With this NFT, you are providing liquidity
                to our network and will get rewarded in ZERO for it. And don&apos;t forget, if you
                choose to burn this NFT last, you will get the most ZERO!
            </p>

            <div className="flex flex-col items-center gap-4 py-6">
                <HeroGallery size="160" center />
            </div>

            <Button className="w-full mt-4" onClick={() => setOpen(false)}>
                Close
            </Button>
        </ModalBase>
    );
};
