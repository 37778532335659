import React, { ReactNode } from 'react';

type IGridProps = {
    children: ReactNode;
    cols?: string;
    gap?: string;
    className?: string;
    centeredItems?: boolean;
};

export const GridLayout = ({ children, cols, gap, className = '', centeredItems }: IGridProps) => {
    const colsClass = cols ? cols : 'lg:grid-cols-2';
    const gapClass = gap ? gap : 'gap-8';
    const centeredClass = centeredItems ? 'justify-items-center' : '';
    return (
        <div className={`grid grid-cols-1 ${colsClass} ${gapClass} ${className} ${centeredClass}`}>
            {children}
        </div>
    );
};
