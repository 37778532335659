import React from 'react';

type ILogoProps = {
    xl?: boolean;
    svg?: boolean;
};

const Logo = (props: ILogoProps) => {
    const determineSource = () => `/logos/logo-${props.xl ? 'full' : 'only'}.svg`;
    const determineWidth = () => (props.xl ? '225px' : '50px');

    return (
        <img src={determineSource()} alt="zeroDAO Logo" height="50px" width={determineWidth()} />
    );
};

export { Logo };
