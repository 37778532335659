import { Transition } from '@headlessui/react';
import React from 'react';
import { Button, Dropdown, IInputErrorProps, Input } from '../components';
import { RiErrorWarningLine } from 'react-icons/ri';

type ITokenInputProps = {
    value: string;
    onChange: any;
    balance?: string;
    maxFx?: any;
    asset?: string;
    error?: string | false;
};

export const TokenInput = ({
    value,
    onChange,
    maxFx,
    balance = '0.0',
    asset = 'ETH',
    error,
}: ITokenInputProps) => {
    return (
        <div className="flex flex-col gap-1">
            <div className="bg-brand-gray rounded-lg flex flex-col justify-between">
                <div className="w-full flex gap-2 justify-between">
                    <Input
                        placeholder="0.0"
                        className="bg-transparent outline-none"
                        value={value}
                        onChange={onChange}
                        size="3xl"
                        type="token"
                        max={balance}
                        allowMax
                    />
                    <Dropdown
                        disabled
                        color="transparent"
                        buttonClass="font-bold text-xl"
                        button={asset}
                        items={[]}
                    />
                </div>
                <div className="flex justify-end">
                    <Button onClick={maxFx} color="transparent">
                        Balance: {balance}
                    </Button>
                </div>
            </div>

            {error && (
                <Transition
                    show={error !== ''}
                    enter="transition-opacity duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="transition-opacity duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="text-yellow-500 flex items-center gap-2">
                        <RiErrorWarningLine />
                        <p className="text-sm">{error}</p>
                    </div>
                </Transition>
            )}
        </div>
    );
};
