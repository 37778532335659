import React, { useState } from 'react';
import { Button, Card, SpinnerLoader } from '../components';
import { RiGasStationFill } from 'react-icons/ri';
import { BiChevronDown } from 'react-icons/bi';
import Collapsible from 'react-collapsible';
import { addDollarAmounts } from '../../utils/helpers';

type ITransactionFeesProps = {
    impact?: string;
    network?: string;
    loading?: boolean;
    gas?: string;
};

export const TransactionFees = ({
    impact,
    network,
    loading,
    gas = '$0.00',
}: ITransactionFeesProps) => {
    const [show, setShow] = useState(false);
    const toggle = () => setShow(!show);

    const createFeeList = () => {
        const list = [{ text: 'Estimated Gas', fee: `${gas}` }];
        if (network) list.push({ text: 'Network Fee', fee: `${network}` });
        if (impact) list.push({ text: 'Price Impact', fee: impact });
        return list;
    };

    const sumFees = () => addDollarAmounts(createFeeList().map((el) => el.fee));

    return (
        <Collapsible
            triggerDisabled={loading || sumFees() === '$0.00'}
            transitionTime={150}
            easing="ease-out"
            trigger={
                <Button
                    onClick={toggle}
                    between
                    color="gray"
                    className="hover:!bg-brand-gray hover:text-neutral-300 w-full"
                >
                    <div className="flex gap-2 items-center">
                        <RiGasStationFill />
                        <span>Fee</span>
                    </div>
                    <div className="flex gap-1 items-center">
                        <SpinnerLoader loading={loading} />
                        <span className={`${loading ? 'animate-pulse' : ''}`}>{sumFees()}</span>
                        <BiChevronDown
                            size={'24px'}
                            className={`transition duration-150 ${
                                !loading && sumFees() !== '$0.00' && show
                                    ? 'rotate-180'
                                    : 'rotate-0'
                            }`}
                        />
                    </div>
                </Button>
            }
        >
            <Card inner className={`flex flex-col origin-top mt-2`}>
                {createFeeList().map((el, i) => (
                    <div key={`fee-details-${i}`} className="flex items-center justify-between">
                        <span>{el.text}</span>
                        <span>{el.fee}</span>
                    </div>
                ))}
            </Card>
        </Collapsible>
    );
};
