import React, { useEffect } from 'react';
import { useTabs, useTokenInput, useTransaction } from '../../hooks';
import { Button, Card, CardHeader, Dropdown, Tabs, TabsChild } from '../components';
import { TokenInput, TransactionFees, TransactionOutput } from '../features';
import { MdSettings } from 'react-icons/md';
import { useUserBalancesContext } from '../../stores';
import { useContracts } from '../../api/use-contracts';
import { getContractAddress } from '../../api/common';

function ZeroBridge() {
    const { zeroContract } = useContracts();
    const { zero, sZero } = useUserBalancesContext();
    const { submitTx, isLoading, error } = useTransaction();
    const { value, setValue, onChange, maxToggle, isValid } = useTokenInput();
    const { steps, activeTab, toggleTab } = useTabs([{ name: 'Burn' }, { name: 'Release' }]);

    const determineButtonText = () => {
        if (!value) return 'Approve';
        if (activeTab === 'Burn') {
            return 'Burn';
        } else {
            return 'Release';
        }
    };

    const handleSubmit = async () => {
        if (error) setValue('');
        return;
    };

    useEffect(() => setValue(''), [activeTab]);

    const iconSize = '18px';

    return (
        <Card
            className="flex flex-col gap-6 justify-between w-full mx-auto !pt-2"
            construction
            maxWidth
        >
            <Tabs steps={steps} setTab={toggleTab} activeTab={activeTab} />

            {/* Burn */}
            <TabsChild active={activeTab === 'Burn'}>
                <CardHeader>
                    <h4 className="text-lg font-bold">Burn</h4>
                    <Dropdown
                        color="transparent"
                        buttonClass="hover:!text-neutral-300"
                        button={<MdSettings size={iconSize} />}
                    >
                        <span>Under Construction</span>
                    </Dropdown>
                </CardHeader>
                <div className="flex flex-col gap-2">
                    <TokenInput
                        value={value}
                        onChange={onChange}
                        maxFx={() => maxToggle('0.0')}
                        balance={'0.0'}
                        asset={'ETH'}
                    />
                    <TransactionFees />
                    <TransactionOutput asset={{ name: 'BTC', address: getContractAddress() }} />
                </div>
            </TabsChild>

            {/* Release */}
            <TabsChild active={activeTab === 'Release'}>
                <CardHeader>
                    <h4 className="text-lg font-bold">Release</h4>
                    <Dropdown color="transparent" button={<MdSettings size={iconSize} />}>
                        <span>Under Construction</span>
                    </Dropdown>
                </CardHeader>
                <div className="flex flex-col gap-2">
                    <TokenInput
                        value={value}
                        onChange={onChange}
                        maxFx={() => maxToggle('0.0')}
                        balance={'0.0'}
                        asset={'BTC'}
                    />
                    <TransactionFees />
                    <TransactionOutput asset={{ name: 'ETH', address: getContractAddress() }} />
                </div>
            </TabsChild>

            <Button
                onClick={handleSubmit}
                className="w-full"
                loading={isLoading}
                // disabled={!isValid()}
                disabled
            >
                Coming Soon
                {/* {determineButtonText()} */}
            </Button>
        </Card>
    );
}

export { ZeroBridge };
