import React from 'react';

type ICopyrightProps = {
    center?: boolean;
    margin?: `m${string}`;
};

export const Copyright = ({ center, margin }: ICopyrightProps) => {
    const centerClass = center ? 'text-center' : '';
    const marginClass = margin ? margin : '';
    return (
        <p className={`text-neutral-200 text-xs ${centerClass} ${marginClass}`}>
            Powered By{' '}
            <a href="https://zerodao.com" target="_blank" rel="noreferrer">
                zeroDAO{' '}
            </a>
            <br />
            Copyright &copy; 2022 Z DAO LLC
        </p>
    );
};
