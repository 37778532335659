import { BigNumber } from 'ethers';
import React, { createContext, ReactNode, useContext, useEffect, useState } from 'react';
import { useAccount, useBalance, useNetwork } from 'wagmi';
import { CHAIN_CONFIGURATIONS } from '../api';
import { useContracts } from '../api/use-contracts';
import { NETWORK } from '../utils/constants';

// Types
type ITokenBalanceProps = {
    decimals: number;
    formatted: string;
    symbol: string;
    value: BigNumber;
};

export type IUserBalancesStoreProps = {
    zero?: ITokenBalanceProps;
    eth?: ITokenBalanceProps;
    sZero?: ITokenBalanceProps;
    heroes: Array<any>;
    setHeroes: React.Dispatch<React.SetStateAction<any>>;
};

// Context
const UserBalancesContext = createContext<IUserBalancesStoreProps>({
    heroes: [],
    setHeroes() {},
});

// Wrapper
export function UserBalancesStore(props: { children: ReactNode }) {
    const [heroes, setHeroes] = useState([]);
    const { zeroContract, sZeroContract, zeroHeroContract } = useContracts();
    const { address } = useAccount();
    const { chain } = useNetwork();
    const correctNetwork = chain?.id === CHAIN_CONFIGURATIONS[NETWORK].chainId;

    const baseOptions = { address, watch: true, chainId: CHAIN_CONFIGURATIONS[NETWORK].chainId };

    const ethBal = useBalance(baseOptions);
    const zeroBal = useBalance({
        ...baseOptions,
        token: zeroContract?.address as any,
    });
    const sZeroBal = useBalance({
        ...baseOptions,
        token: sZeroContract?.address as any,
    });

    useEffect(() => {
        const getter = async () => {
            if (zeroHeroContract && address && correctNetwork) {
                const temp: any = heroes.length > 0 ? [...heroes] : [];
                try {
                    for (let i = 0; i < 2001; i++) {
                        const isOwner = await zeroHeroContract.ownerOf(i);
                        if (isOwner) temp.push(i.toString());
                    }
                } catch (err) {
                    // Handle error
                }
                setHeroes(temp);
            }
        };
        getter();
    }, [address]);

    useEffect(() => {
        if (!address) setHeroes([]);
    }, [address]);

    return (
        <UserBalancesContext.Provider
            value={{
                zero: zeroBal.data,
                eth: ethBal.data,
                sZero: sZeroBal.data,
                heroes,
                setHeroes,
            }}
        >
            {props.children}
        </UserBalancesContext.Provider>
    );
}

// Independent
export function useUserBalancesContext() {
    return useContext(UserBalancesContext);
}
