import { MouseEventHandler, useState } from 'react';
import { ITabsItemProps } from '../ui/components';

export function useTabs(tabs: ITabsItemProps[]) {
    const [steps, setSteps] = useState<ITabsItemProps[]>(tabs);
    const [activeStep, setActiveStep] = useState(0);
    const [activeTab, setActiveTab] = useState(tabs[0].name);

    const nextStep = () => {
        const shallow = [...steps];
        const current = shallow.findIndex((el) => el.status === 'current');
        if ((current || current === 0) && current !== shallow.length - 1)
            shallow[current].status = 'complete';
        const next = current + 1;
        if (next < shallow.length) {
            shallow[next].status = 'current';
            setActiveStep(activeStep + 1);
        }
        setSteps(shallow);
    };

    const prevStep = () => {
        const shallow = [...steps];
        const current = shallow.findIndex((el) => el.status === 'current');
        if (current) shallow[current].status = 'upcoming';
        const prev = current - 1;
        if (prev >= 0) {
            shallow[prev].status = 'current';
            setActiveStep(activeStep - 1);
        }
        setSteps(shallow);
    };

    const toggleTab = (e: any) => setActiveTab(e.target.innerText);

    return {
        steps,
        activeStep,
        nextStep,
        prevStep,
        toggleTab,
        activeTab,
    };
}
