import React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import { Card, CardHeader } from '../components';
import { MdClose } from 'react-icons/md';

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
};

type IModalBaseProps = {
    open: boolean;
    handleClose: () => void;
    handleToggle?: () => void;
    title: string;
    desc?: string;
    children: React.ReactNode;
    maxWidth?: `max-w-${string}`;
};

export function ModalBase({
    open,
    handleClose,
    handleToggle,
    title,
    desc,
    children,
    maxWidth,
}: IModalBaseProps) {
    return (
        <Modal
            aria-labelledby={title}
            aria-describedby={desc ? desc : 'modal'}
            open={open}
            onClose={handleClose}
            closeAfterTransition
        >
            <Fade in={open} timeout={{ enter: 800, exit: 200 }}>
                <Box
                    sx={style}
                    className={`w-full p-4 md:p-0 ${maxWidth ? maxWidth : 'max-w-[600px]'}`}
                >
                    <Card className="w-full">
                        <div className="lg:py-2">
                            <CardHeader>
                                <h4 className="text-xl font-bold">{title}</h4>
                                <button
                                    onClick={handleClose}
                                    className="pl-2 pb-1 transition duration-150 hover:text-neutral-300"
                                >
                                    <MdClose size="24px" />
                                </button>
                            </CardHeader>
                            {children}
                        </div>
                    </Card>
                </Box>
            </Fade>
        </Modal>
    );
}
