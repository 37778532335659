import { useTransactionsContext, useUserBalancesContext } from '../stores';
import { Dispatch, SetStateAction, useState } from 'react';

type IUseTransactionProps = {
    isSuccess: boolean;
    setIsSuccess: Dispatch<SetStateAction<boolean>>;
    isLoading: boolean;
    setIsLoading: Dispatch<SetStateAction<boolean>>;
    error: string;
    setError: Dispatch<SetStateAction<string>>;
    submitTx: (callback?: any, nft?: boolean) => Promise<void>;
};

export const useTransaction = (): IUseTransactionProps => {
    const { newTransaction } = useTransactionsContext();
    const { heroes, setHeroes } = useUserBalancesContext();
    const [isSuccess, setIsSuccess] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState('');

    const submitTx = async (callback: any, nft?: boolean) => {
        if (!error) {
            setIsLoading(true);
            try {
                const tx = await callback();
                newTransaction(tx);
                if (tx) {
                    if (nft) {
                        const { events } = await tx.wait();
                        if (events && events.length > 0) {
                            const temp = heroes.length > 0 ? [...heroes] : [];
                            events.map(({ args }: any) => {
                                const { tokenId } = args;
                                if (tokenId) temp.push(tokenId.toString());
                            });
                            setHeroes(temp);
                        }
                    }
                    setIsLoading(false);
                    setError('');
                    setIsSuccess(true);

                    setTimeout(() => {
                        setIsSuccess(false);
                    }, 6000);
                }
            } catch (err) {
                console.error('Error in submitting tx:', err);
                setError(String(err));
                setIsSuccess(false);
                setTimeout(() => {
                    setError('');
                }, 1000);
            }
            setIsLoading(false);
        }
        return;
    };

    return {
        isSuccess,
        setIsSuccess,
        isLoading,
        setIsLoading,
        error,
        setError,
        submitTx,
    };
};
