import React from 'react';
import { FaDiscord, FaGithub, FaTwitter } from 'react-icons/fa';
import { SOCIALS } from '../../utils/constants';

type ISocialsProps = {
    col?: boolean;
    size?: 'sm' | 'md' | 'lg';
};

export const Socials = ({ col, size }: ISocialsProps) => {
    const flexDirClass = col ? `flex-col` : `flex-row`;
    const determineSize = () => {
        switch (size) {
            case 'lg':
                return '24px';
            case 'sm':
                return '12px';
            default:
                return '18px';
        }
    };
    return (
        <ul className={`flex gap-4 ${flexDirClass}`}>
            {SOCIALS.map((el, i) => (
                <li key={`social-${i}`} className="transition duration-150 hover:text-neutral-300">
                    <a href={el.url} target="_blank" rel="noreferrer">
                        <el.icon size={determineSize()} />
                    </a>
                </li>
            ))}
        </ul>
    );
};
