import React from 'react';
import { truncate } from '../../utils/helpers';

type IToastStatusProps = {
    status: 'error' | 'success' | 'pending';
    transaction?: string;
    message?: string;
};

export const ToastStatus = ({ status, transaction, message }: IToastStatusProps) => {
    const determineText = () => {
        if (!message) {
            switch (status) {
                case 'error':
                    return `${transaction ? 'Error while submitted transaction' : 'Error'}`;
                case 'success':
                    return `${transaction ? 'Transaction completed' : 'Success'}`;
                case 'pending':
                    return `${transaction ? 'Transaction submitted' : 'Loading'}`;
            }
        } else {
            return message;
        }
    };
    return (
        <div className="w-full flex flex-col justify-between">
            <span>{determineText()}</span>
            {transaction && (
                <a
                    href={`https://etherscan.io/tx/${transaction}`}
                    target="_blank"
                    rel="noreferrer"
                    className="underline text-brand-blue hover:text-brand-purple transition duration-150"
                >
                    {truncate(transaction)}
                </a>
            )}
        </div>
    );
};
