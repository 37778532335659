import React, { useEffect } from 'react';
import { useTabs, useTokenInput, useTransaction } from '../../hooks';
import { Button, Card, CardHeader, Dropdown, Tabs, TabsChild } from '../components';
import { TokenInput, TransactionFees, TransactionOutput } from '../features';
import { MdSettings } from 'react-icons/md';
import { useStaking } from '../../api/use-staking';
import { useUserBalancesContext } from '../../stores';
import { usdFormatter } from '../../utils/helpers';
import { useContracts } from '../../api/use-contracts';
import { getContractAddress } from '../../api/common';
import { useAccount } from 'wagmi';

function StakeZero() {
    const { zeroContract } = useContracts();
    const { zero, sZero } = useUserBalancesContext();
    const { deposit, approve, isApproved, withdraw, fees, getRewards, getFees, rewards } =
        useStaking();
    const { submitTx, isLoading, error } = useTransaction();
    const { value, setValue, onChange, maxToggle, isValid } = useTokenInput();
    const { steps, activeTab, toggleTab } = useTabs([{ name: 'Deposit' }, { name: 'Withdraw' }]);
    const { address } = useAccount();

    const iconSize = '18px';
    const getBalance = () => (activeTab === 'Deposit' ? zero?.formatted : sZero?.formatted);

    const determineButtonText = () => {
        if (!isApproved) return 'Approve';
        if (activeTab === 'Deposit') {
            return 'Deposit';
        } else {
            return 'Withdraw';
        }
    };

    const handleSubmit = async () => {
        if (!isApproved) {
            await submitTx(async () => {
                const approveRes = await approve(value ? value : '0');
                return approveRes;
            });
        }
        if (isApproved) {
            await submitTx(async () => {
                let res;
                if (activeTab === 'Withdraw') {
                    res = await withdraw(value);
                } else {
                    res = await deposit(value);
                }
                setValue('');
                return res;
            });
        }
        if (error) setValue('');
        return;
    };

    // On tab change
    useEffect(() => setValue(''), [activeTab]);
    // On amount and tab change
    useEffect(() => {
        const input = value || '0';
        const getter = async () => {
            const type = activeTab === 'Deposit' ? 'enter' : 'leave';
            await getFees.getGasFees(input, type, !isValid(getBalance()) || !isApproved);
            if (activeTab === 'Withdraw') await getRewards(address);
        };
        getter();
    }, [value, activeTab, sZero?.formatted]);

    return (
        <Card
            className="flex flex-col gap-6 justify-between w-full mx-auto !pt-2"
            maxWidth
            construction
        >
            <Tabs steps={steps} setTab={toggleTab} activeTab={activeTab} />

            {/* DEPOSIT */}
            <TabsChild active={activeTab === 'Deposit'}>
                <CardHeader>
                    <h4 className="text-lg font-bold">Deposit ZERO</h4>
                    <Dropdown
                        color="transparent"
                        buttonClass="hover:!text-neutral-300"
                        button={<MdSettings size={iconSize} />}
                    >
                        <span>Under Construction</span>
                    </Dropdown>
                </CardHeader>
                <div className="flex flex-col gap-2">
                    <TokenInput
                        value={value}
                        onChange={onChange}
                        maxFx={() => maxToggle(getBalance())}
                        balance={getBalance()}
                        asset={'ZERO'}
                        error={value !== '' && !isValid(getBalance()) && 'Input exceeds balance'}
                    />
                    <TransactionFees gas={usdFormatter(false).format(fees.gasFees.usd)} />
                    <TransactionOutput
                        asset={{ name: 'sZERO', address: getContractAddress(zeroContract) }}
                        output={value || '0.0'}
                    />
                </div>
            </TabsChild>

            {/* WITHDRAW */}
            <TabsChild active={activeTab === 'Withdraw'}>
                <CardHeader>
                    <h4 className="text-lg font-bold">Withdraw ZERO</h4>
                    <Dropdown color="transparent" button={<MdSettings size={iconSize} />}>
                        <span>Under Construction</span>
                    </Dropdown>
                </CardHeader>
                <div className="flex flex-col gap-2">
                    <TokenInput
                        value={value}
                        onChange={onChange}
                        maxFx={() => maxToggle(getBalance())}
                        balance={getBalance()}
                        asset={'sZERO'}
                        error={value !== '' && !isValid(getBalance()) && 'Input exceeds balance'}
                    />
                    <TransactionFees gas={usdFormatter(false).format(fees.gasFees.usd)} />
                    <TransactionOutput
                        asset={{ name: 'ZERO', address: getContractAddress(zeroContract) }}
                        accumalated={rewards}
                        output={(parseFloat(rewards) + parseFloat(getBalance() || '0')).toString()}
                    />
                </div>
            </TabsChild>

            <Button
                requiresWallet
                onClick={handleSubmit}
                className="w-full"
                color={activeTab === 'Deposit' ? 'green' : 'border'}
                loading={isLoading}
                // disabled={!isValid(getBalance()) && isApproved}
                disabled
            >
                {/* {determineButtonText()} */}
                Coming Soon
            </Button>
        </Card>
    );
}

export { StakeZero };
