import { utils, constants } from 'ethers';
import { useEffect, useState } from 'react';
import { useAccount, useFeeData, useNetwork, useSigner } from 'wagmi';
import { useApprovedTokensContext } from '../stores';
import { NETWORK } from '../utils/constants';
import { CHAIN_CONFIGURATIONS, DEFAULT_GAS_LIMIT } from './common';
import { useContracts } from './use-contracts';
import { usePrices } from './use-prices';

export const useStaking = () => {
    const prices = usePrices();
    const { data } = useFeeData();
    const [isApproved, setIsApproved] = useState(false);
    const [gasFees, setGasFees] = useState({ usd: 0, eth: '0' });
    const [networkFees, setNetworkFees] = useState({ usd: 0, eth: '0' });
    const [apy, setApy] = useState(0);
    const [rewards, setRewards] = useState('0');
    const { zeroContract, sZeroContract } = useContracts();
    const { data: signer } = useSigner();
    const { updateApproved, approved } = useApprovedTokensContext();
    const { address } = useAccount();
    const { chain } = useNetwork();
    const correctNetwork = chain?.id === CHAIN_CONFIGURATIONS[NETWORK].chainId;

    const approve = async (amount: string, blocker?: boolean) => {
        if (!zeroContract || !sZeroContract || !signer || blocker || !correctNetwork) return;
        const formatAmt = utils.parseEther(amount);
        const approve = await zeroContract
            .connect(signer)
            .approve(sZeroContract.address, constants.MaxUint256);
        console.log('Approved for ZERO spend');
        if (approve.hash) {
            setIsApproved(true);
            updateApproved('ZERO');
        }
        return approve;
    };

    const deposit = async (amount: string, blocker?: boolean) => {
        if (!zeroContract || !sZeroContract || !signer || !isApproved || blocker || !correctNetwork)
            return;
        const formatAmt = utils.parseEther(amount);
        const deposit = await sZeroContract
            .connect(signer)
            .enterStaking(formatAmt, { gasLimit: DEFAULT_GAS_LIMIT });
        console.log(`Deposited: ${amount}`);
        return deposit;
    };

    const withdraw = async (amount: string, blocker?: boolean) => {
        if (!zeroContract || !sZeroContract || !signer || blocker || !correctNetwork) return;
        const formatAmt = utils.parseEther(amount);
        const withdraw = await sZeroContract
            .connect(signer)
            .leaveStaking(formatAmt, { gasLimit: DEFAULT_GAS_LIMIT });
        console.log(`Withdrew: ${amount}`);
        return withdraw;
    };

    const estimateGasFees = async (amount: string, type: 'enter' | 'leave', blocker?: boolean) => {
        let output = { usd: 0, eth: '0' };
        if (!zeroContract || !sZeroContract || !signer || blocker || !correctNetwork) return output;
        const formatAmt = utils.parseEther(amount || '0');

        let ethEstimate;
        if (type === 'enter') {
            ethEstimate = await sZeroContract
                ?.connect(signer)
                .estimateGas.enterStaking(formatAmt, { gasLimit: DEFAULT_GAS_LIMIT });
        }
        if (type === 'leave') {
            ethEstimate = await sZeroContract
                ?.connect(signer)
                .estimateGas.leaveStaking(formatAmt, { gasLimit: DEFAULT_GAS_LIMIT });
        }
        ethEstimate = utils.formatEther(ethEstimate?.mul(data?.gasPrice || '0') || '0');
        output = {
            usd: parseFloat(ethEstimate) * parseFloat(prices?.eth || '0'),
            eth: ethEstimate,
        };
        setGasFees(output);
        return output;
    };

    const estimateNetworkFees = async (
        amount: string,
        type: 'enter' | 'leave',
        blocker?: boolean,
    ) => {
        let output = { usd: 0, eth: '0' };
        if (!zeroContract || !sZeroContract || !signer || blocker || !correctNetwork) return output;
        const formatAmt = utils.parseEther(amount || '0');

        return output;
    };

    const estimateRewards = async (address?: string) => {
        if (!zeroContract || !sZeroContract || !signer || !address || !correctNetwork) return '0';
        let reward: any = await sZeroContract?.pendingZero(0, utils.getAddress(address), {
            gasLimit: DEFAULT_GAS_LIMIT,
        });
        reward = utils.formatEther(reward);
        setRewards(reward);
        return reward;
    };

    useEffect(() => {
        if (approved.includes('ZERO')) {
            setIsApproved(true);
        } else {
            setIsApproved(false);
        }
    }, [approved, address]);

    return {
        withdraw,
        approve,
        deposit,
        isApproved,
        fees: {
            gasFees,
            networkFees,
        },
        getFees: {
            getGasFees: estimateGasFees,
            getNetworkFees: estimateNetworkFees,
        },
        apy,
        getRewards: estimateRewards,
        rewards,
    };
};
