import { Transition } from '@headlessui/react';
import { ConnectButton } from '@rainbow-me/rainbowkit';
import React from 'react';
import { useAccount, useDisconnect } from 'wagmi';
import { CHAIN_CONFIGURATIONS } from '../../api/common';
import { useHeroes } from '../../api/use-heroes';
import { useUIContext } from '../../stores';
import { IPFS_GATEWAY_URL, ZHERO_IMG_CID, NETWORK } from '../../utils/constants';
import { truncate } from '../../utils/helpers';
import { Button, Dropdown } from '../components';

type IWalletButtonProps = {
    fullWidth?: boolean;
    hero?: boolean;
};

export const WalletButton = (props: IWalletButtonProps) => {
    const { disconnect } = useDisconnect();
    const { address } = useAccount();
    const renderContent = address ? truncate(address) : 'Connect Wallet';
    const { heroes } = useHeroes();
    const { activeHero, setActiveHero } = useUIContext();

    return (
        <ConnectButton.Custom>
            {({
                account,
                chain,
                openAccountModal,
                openChainModal,
                openConnectModal,
                authenticationStatus,
                mounted,
            }) => {
                // Note: If your app doesn't use authentication, you
                // can remove all 'authenticationStatus' checks
                const ready = mounted && authenticationStatus !== 'loading';
                const connected =
                    ready &&
                    account &&
                    chain &&
                    (!authenticationStatus || authenticationStatus === 'authenticated');

                const determineConnection = () => {
                    if (!connected) {
                        return { onClick: () => openConnectModal(), render: renderContent };
                    } else if (
                        chain.unsupported ||
                        chain.id !== CHAIN_CONFIGURATIONS[NETWORK].chainId
                    ) {
                        return { onClick: () => openChainModal(), render: 'Wrong Network' };
                    } else {
                        return {
                            onClick: () => disconnect(),
                            render: account.displayName,
                        };
                    }
                };

                return (
                    <div
                        {...(!ready && {
                            'aria-hidden': true,
                            style: {
                                opacity: 0,
                                pointerEvents: 'none',
                                userSelect: 'none',
                            },
                        })}
                    >
                        {(() => {
                            return (
                                <div className="flex items-center gap-2">
                                    <Button
                                        onClick={determineConnection().onClick}
                                        className={props.fullWidth ? 'w-full' : ''}
                                    >
                                        {determineConnection().render}
                                    </Button>
                                    {props.hero && heroes.length > 0 && (
                                        <Transition
                                            show={heroes.length > 0}
                                            enter="transition-opacity duration-75"
                                            enterFrom="opacity-0"
                                            enterTo="opacity-100"
                                            leave="transition-opacity duration-150"
                                            leaveFrom="opacity-100"
                                            leaveTo="opacity-0"
                                            className="flex items-center"
                                        >
                                            <Dropdown
                                                color="transparent"
                                                buttonClass="!p-0"
                                                menuClass="!w-20"
                                                button={
                                                    <div className="animate-border inline-block from-brand-900 via-orange-400 to-brand-100 bg-[length:500%_500%] bg-gradient-to-l rounded-full p-0.5">
                                                        <img
                                                            src={`${IPFS_GATEWAY_URL}${ZHERO_IMG_CID}/${activeHero}.png`}
                                                            alt="Zero Hero Avatar"
                                                            className="rounded-full w-10 h-10 md:w-12 md:h-12 object-cover object-top"
                                                        />
                                                    </div>
                                                }
                                                items={heroes}
                                                setState={setActiveHero}
                                            />
                                        </Transition>
                                    )}
                                </div>
                            );
                        })()}
                    </div>
                );
            }}
        </ConnectButton.Custom>
    );
};
