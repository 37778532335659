import { FaDiscord, FaGithub, FaTwitter } from 'react-icons/fa';
import { IAvailableChains } from '../api/types';

// GENERAL
export const NETWORK: IAvailableChains =
    (process.env.REACT_APP_NETWORK as IAvailableChains) || 'ETHEREUM';
export const TESTING = NETWORK === 'LOCALHOST' ? true : false;

export const EXPLORER_URLS: Record<IAvailableChains, string> = {
    ETHEREUM: 'https://etherscan.com',
    GOERLI: 'https://goerli.etherscan.io',
    LOCALHOST: '',
};

// SOCIALS
export const SOCIALS = [
    {
        name: 'Discord',
        url: 'https://discord.gg/TNNSxFXcgU',
        icon: FaDiscord,
    },
    {
        name: 'Github',
        url: 'https://github.com/zerodao-finance',
        icon: FaGithub,
    },
    {
        name: 'Twitter',
        url: 'https://twitter.com/zerodaohq',
        icon: FaTwitter,
    },
];

// ZERO HEROES
export const IPFS_GATEWAY_URL = 'https://gateway.pinata.cloud/ipfs/';
export const ZHERO_META_CID =
    process.env.REACT_APP_ZHERO_META_CID || 'QmR4Kdi64gwJQ2AWkbHEfKrnDTLybECXHwCewqAC8FNhxJ';
export const ZHERO_IMG_CID =
    process.env.REACT_APP_ZHERO_IMG_CID || 'QmcotzBG6cy64AsjXsXjRxJHRqBdZ8CJBTUxZQkgJip5WJ';
export const ZHERO_WHITELISTED = require(`@zerodao/token/merkle/${
    TESTING ? 'localhost' : 'mainnet'
}/zhero-whitelist.json`).claims;
export const ZHERO_PRIVATELISTED = require(`@zerodao/token/merkle/${
    TESTING ? 'localhost' : 'mainnet'
}/zhero-privatelist.json`).claims;
export const ZHERO_MAX = 10;
export const ZHERO_PRICE = 0.3;
