import { Transaction } from 'ethers';
import React, { createContext, ReactNode, useContext, useState } from 'react';
import { toast } from 'react-toastify';
import { ToastStatus } from '../ui/components';
import { updateToast } from '../utils/toast';

// Types
type ITransactionProps = {
    text: string;
    status: 'pending' | 'complete' | 'error';
    onClick?: any;
};

export type ITransactionsStoreProps = {
    transactions: Array<ITransactionProps>;
    setTransactions?: any;
    newTransaction: (tx: Transaction) => void;
    updateTransaction: (hash: string, status: string) => void;
};

// Context
const TransactionsContext = createContext<ITransactionsStoreProps>({
    transactions: [],
    newTransaction(tx) {},
    updateTransaction(hash, status) {},
});

// Wrapper
export function TransactionsStore(props: { children: ReactNode }) {
    const [transactions, setTransactions] = useState<Array<ITransactionProps>>([]);

    const newTransaction = async (tx: Transaction) => {
        if (!tx?.hash) return;
        const { hash } = tx;
        const toastId = toast.loading(<ToastStatus status="pending" transaction={tx.hash} />, {});
        const shallow1 = [...transactions];
        shallow1.push({ text: hash, status: 'pending' });
        setTransactions(shallow1);

        try {
            const receipt = await (tx as any).wait();
            if (receipt?.confirmations >= 1) {
                updateTransaction(hash);
                updateToast(toastId, hash, 'success');
            } else {
                updateTransaction(hash, 'error');
                updateToast(toastId, hash, 'error');
            }
        } catch (err) {
            updateTransaction(hash, 'error');
            updateToast(toastId, hash, 'error', `${String(err).slice(0, 120)}...`);
        }
    };

    const updateTransaction = (hash: string, status = 'complete') => {
        const shallow = [...transactions];
        shallow.map((obj) => {
            if (obj.text === hash) return { ...obj, status };
            return obj;
        });
        setTransactions(shallow);
    };

    return (
        <TransactionsContext.Provider
            value={{
                transactions,
                setTransactions,
                newTransaction,
                updateTransaction,
            }}
        >
            {props.children}
        </TransactionsContext.Provider>
    );
}

// Independent
export function useTransactionsContext() {
    return useContext(TransactionsContext);
}
