import React, { MouseEventHandler, ReactNode } from 'react';

export type ITabsItemProps = {
    name: string;
    status?: 'complete' | 'current' | 'upcoming';
};

export type ITabsProps = {
    steps: ITabsItemProps[];
    previous?: MouseEventHandler<HTMLButtonElement>;
    next?: MouseEventHandler<HTMLButtonElement>;
    noSkip?: boolean;
    ordered?: boolean;
    setTab?: MouseEventHandler<HTMLButtonElement>;
    activeTab?: string;
};

export const Tabs = ({ steps, previous, next, noSkip, ordered, setTab, activeTab }: ITabsProps) => {
    return (
        <nav aria-label="Progress">
            <ol className={`grid grid-cols-${steps.length} gap-3`}>
                {steps.map((step, i) => (
                    <li key={step.name} className="md:flex-1">
                        {step.status === 'complete' ? (
                            <button
                                className="w-full group flex flex-col border-brand-900 py-2 hover:border-brand-900 border-b-4 opacity-70 hover:opacity-100 transition duration-150"
                                onClick={previous}
                            >
                                {ordered && (
                                    <span className="text-sm font-medium text-brand-900 group-hover:text-brand-900">{`Step ${
                                        i + 1
                                    }`}</span>
                                )}

                                <span className="text-sm font-medium">{step.name}</span>
                            </button>
                        ) : step.status === 'current' ? (
                            <button
                                className="w-full flex flex-col border-brand-100 py-2 border-b-4"
                                aria-current="step"
                            >
                                {ordered && (
                                    <span className="text-sm font-medium text-brand-100">{`Step ${
                                        i + 1
                                    }`}</span>
                                )}
                                <span className="text-sm font-medium">{step.name}</span>
                            </button>
                        ) : (
                            <button
                                className={`w-full group flex flex-col py-2 border-b-4 transition duration-150 ${
                                    setTab
                                        ? `${
                                              activeTab === step.name
                                                  ? 'border-brand-100 text-brand-100'
                                                  : 'hover:border-neutral-300 hover:text-neutral-300'
                                          }`
                                        : 'border-gray-200 hover:border-gray-300'
                                }`}
                                onClick={noSkip ? () => {} : next || setTab}
                            >
                                {ordered && (
                                    <span className="text-sm font-medium">{`Step ${i + 1}`}</span>
                                )}
                                <span
                                    className={`text-sm font-medium ${
                                        setTab ? 'text-center w-full' : ''
                                    }`}
                                >
                                    {step.name}
                                </span>
                            </button>
                        )}
                    </li>
                ))}
            </ol>
        </nav>
    );
};

export const TabsChild = ({
    children,
    active,
}: {
    children: ReactNode | ReactNode[];
    active: boolean;
}) => (
    <div className={`${active ? 'block' : 'hidden'}`}>
        <>{children}</>
    </div>
);
