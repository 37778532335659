import React, { ReactNode } from 'react';
import { useAccount, useNetwork } from 'wagmi';
import { CHAIN_CONFIGURATIONS } from '../../api';
import { useWindowDimensions } from '../../hooks';
import { NETWORK } from '../../utils/constants';
import { WalletButton } from '../features';
import { SpinnerLoader } from './loading-spinner';

export type IButtonProps = {
    children: string | ReactNode;
    link?: string;
    className?: string;
    onClick?: any;
    color?: 'red' | 'green' | 'black' | 'transparent' | 'border' | 'gray';
    disabled?: boolean;
    between?: boolean;
    loading?: boolean;
    as?: 'button' | 'div';
    requiresWallet?: boolean;
    loadingText?: string;
    size?: 'sm' | 'md';
};

export const buttonClass = 'rounded-xl animation duration-150';

export const Button = ({
    children,
    link,
    className,
    onClick,
    disabled,
    color = 'green',
    between,
    loading,
    as,
    requiresWallet,
    loadingText,
}: IButtonProps) => {
    const { address } = useAccount();
    const { chain } = useNetwork();
    const { width, breakpoint } = useWindowDimensions();
    const noWallet = () =>
        requiresWallet && (!address || chain?.id !== CHAIN_CONFIGURATIONS[NETWORK].chainId)
            ? true
            : false;
    const buttonColor = () => {
        switch (color) {
            case 'green':
                return `bg-brand-100 ${disabled || loading ? '' : 'hover:bg-brand-900'}`;
            case 'red':
                return `bg-brand-red ${disabled || loading ? '' : 'hover:bg-brand-red-hover'}`;
            case 'black':
                return `bg-brand-black ${disabled || loading ? '' : 'hover:opacity-80'}`;
            case 'transparent':
                return `${disabled ? '' : 'hover:text-brand-100'}`;
            case 'border':
                return `!border-white ${disabled || loading ? '' : 'hover:bg-neutral-800'}`;
            case 'gray':
                return `bg-brand-gray hover:bg-neutral-800`;
            default:
                return '';
        }
    };
    const typeClass = link
        ? 'hover:text-brand-100 underline'
        : `p-[0.4rem_0.8rem] border border-transparent ${
              disabled || loading ? 'shadow-lg' : ''
          } ${buttonColor()}`;
    const justifyClass = between ? 'justify-between' : 'justify-center';
    const disabledClass = disabled || loading ? 'cursor-not-allowed opacity-75 brightness-75' : '';
    const baseClass = 'flex items-center gap-1 font-semibold';
    const sizeClass = width > breakpoint ? 'py-2' : '';
    if (as === 'div' && !onClick) {
        return (
            <div
                className={`${baseClass} ${justifyClass} ${buttonClass} ${typeClass} ${disabledClass} ${className} ${sizeClass}`}
            >
                <SpinnerLoader loading={loading} />
                {children}
            </div>
        );
    } else {
        if (noWallet()) return <WalletButton fullWidth />;
        else
            return (
                <button
                    onClick={onClick}
                    disabled={disabled || noWallet()}
                    className={`${baseClass} ${justifyClass} ${buttonClass} ${typeClass} ${disabledClass} ${className} ${sizeClass}`}
                >
                    {loading && loadingText ? loadingText : children}
                    <SpinnerLoader loading={loading} />
                </button>
            );
    }
};
